import logo from './logo.svg';
import './App.css';
import Formulario from './Formulario/Formulario';
import React, { useState, useEffect } from 'react';

import { Helmet } from "react-helmet";

console.log(process.env);

function App() {

  const [imageSrc, setImageSrc] = useState('/Bannr-Redemption-Center-Samsung.png');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setImageSrc('/Bannr-Redemption-Center-Samsung-x1.jpg');
      } else {
        setImageSrc('/Bannr-Redemption-Center-Samsung-Mobile-x1.jpg'); // Cambia esto por la URL de la otra imagen
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // Ejecuta la función al cargar el componente

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (

    <>
    
    <Helmet>
        <title>Canjea tu cupón</title>
        <meta name="description" content="Centro de Canje de Cupones Samsung Costa Rica" />
      </Helmet>


    <div className="App">
      <header className="samsung-header">
        <img
          src="/Componente 17 – 1.png"
          alt="banner"
          url="/"
        />
      </header>
      <div>
      <img className='imagen-banner'
        src={imageSrc}
        alt="banner"
      />
      </div>
      <main className="flex min-h-screen flex-col items-center justify-between p-24">
        <div className="container">
          <div className="column">
            <img src="/Icono Compra de producto-Samsung-Latam.png" alt="Imagen 1" className="image" />
            <p className="text">Por la compra de un producto Samsung</p>
          </div>
          <div className="column">
            <img src="Icono Cupon Sorpresa-Samsung-Latam.png" alt="Imagen 2" className="image" />
            <p className="text">Recibes un cupón con un beneficio sorpresa</p>
          </div>
          <div className="column">
            <img src="Icono Redime cupon por regalo-Samsung-Latam.png" alt="Imagen 3" className="image" />
            <p className="text">Redime tu cupón y recibe un regalo</p>
          </div>
        </div>
        <div className="container container-h1">
          <h1>
             Completa el siguiente formulario<br></br> para redimir tu regalo
          </h1>
        </div>
        </main>
      <Formulario></Formulario>
    </div>
    </>
  );
}

export default App;
